import style from "./header.css";
import TabLink from "../Tabs/TabLink";
import Image from "next/image";
import HeaderSelect from "./Header.select";

export default function Header({
	title,
	title2,
	img,
	imgWidth,
	imgHeight,
	tabs,
	onChangeCategory,
	onChangeSort,
	children,
	className = "",
	type,
	multiselect = false,
	sortOptions = [],
	top,
	sortValue,
	categoryValue,
	isShowTabs = true,
}) {
	return (
		<div css={style} style={{ top }} className={className}>
			<div>
				<div className="pageTit">
					<div className="PageTit01">
						{title && <h1 className="first-h1">{title}</h1>}
						{img && (
							<Image src={img} width={imgWidth} height={imgHeight} alt="animation" />
						)}
					</div>
					{title2 && <h1 className="second-h1">{title2}</h1>}
				</div>
				{children}

				{isShowTabs && (
					<HeaderSelect
						categoryOptions={tabs}
						sortOptions={sortOptions}
						onChangeCategory={onChangeCategory}
						onChangeSort={onChangeSort}
						sortValue={sortValue}
						categoryValue={categoryValue}
						filterMultiple={multiselect}
					/>
				)}
			</div>
		</div>
	);
}
