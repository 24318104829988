import { useEffect } from "react";
import Page from "../src/components/common/Page";
import ProductContainer from "../src/components/Product";
import { useRouter } from "next/router";
import { PATH_PRODUCT } from "../src/paths";

export default function PageMain() {
  const { replace } = useRouter();

  useEffect(() => {
    replace(PATH_PRODUCT.root.url);
  }, []);

  return (
    <Page
      isPublic={true}
      title="소팬즈 - SaaS 유저 커뮤니티"
      description="GPT, 노션, Figma 등 업무에 도움되는 생성 AI / 자동화 툴을 소팬즈에서 탐색하세요."
    >
      {/* <ProductContainer /> */}
    </Page>
  );
}
