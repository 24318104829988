import { productCategoryTabs } from "./tab.json";

export const SORT_OPTIONS = [
	{ name: "최신순", value: "latest" },
	{ name: "인기순", value: "popular" },
];

export const ITEM_FILTER_OPTIONS = [
	{
		name: "전체",
		value: "all",
	},
	{
		name: "게시글",
		value: "item",
	},
	{
		name: "리뷰",
		value: "review",
	},
];

export const PRODUCT_FILTER_OPTIONS = [
	{
		name: "전체",
		value: "all",
	},
	...productCategoryTabs,
];
