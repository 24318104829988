export default function CommentIcon({ type }) {
  if (type === "outline") {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.18665 0.499946C5.42665 0.446613 3.73999 1.10661 2.47332 2.33995C1.20665 3.57328 0.506653 5.22661 0.506653 6.99995V12.3333C0.506653 12.9733 1.03332 13.4999 1.67332 13.4999H6.99999C8.77332 13.4999 10.4267 12.7999 11.66 11.5333C12.8933 10.2666 13.5467 8.58661 13.5 6.81995C13.4067 3.43328 10.5733 0.599946 7.18665 0.506613V0.499946ZM10.9467 10.8333C9.89999 11.9066 8.49999 12.4999 7.00665 12.4999H1.66665C1.57332 12.4999 1.49999 12.4266 1.49999 12.3333V6.99995C1.49999 5.49995 2.09332 4.09995 3.16665 3.05995C4.19999 2.05328 5.55999 1.49995 6.99999 1.49995C7.05332 1.49995 7.10665 1.49995 7.15999 1.49995C10.0267 1.57995 12.42 3.97328 12.5 6.83995C12.54 8.33995 11.9867 9.75995 10.9467 10.8333V10.8333Z"
          fill="#777777"
        />
        <path
          d="M7.01331 6.5H7.00664C6.73331 6.5 6.51331 6.72667 6.51331 7C6.51331 7.27333 6.73997 7.5 7.01331 7.5C7.28664 7.5 7.51331 7.27333 7.51331 7C7.51331 6.72667 7.28664 6.5 7.01331 6.5Z"
          fill="#777777"
        />
        <path
          d="M4.34668 6.5H4.34001C4.06668 6.5 3.84668 6.72667 3.84668 7C3.84668 7.27333 4.07335 7.5 4.34668 7.5C4.62001 7.5 4.84668 7.27333 4.84668 7C4.84668 6.72667 4.62001 6.5 4.34668 6.5Z"
          fill="#777777"
        />
        <path
          d="M9.67999 6.5C9.40666 6.5 9.17999 6.72667 9.17999 7C9.17999 7.27333 9.40666 7.5 9.67999 7.5C9.95333 7.5 10.18 7.27333 10.18 7C10.18 6.72667 9.95999 6.5 9.67999 6.5Z"
          fill="#777777"
        />
      </svg>
    );
  }

  if (type == "fill") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.3267 0.453977C9.24666 0.360644 6.29499 1.51564 4.07832 3.67398C1.86166 5.83231 0.636658 8.72564 0.636658 11.829V21.1623C0.636658 22.2823 1.55832 23.204 2.67832 23.204H12C15.1033 23.204 17.9967 21.979 20.155 19.7623C22.3133 17.5456 23.4567 14.6056 23.375 11.514C23.2117 5.58731 18.2533 0.628977 12.3267 0.465644V0.453977ZM7.35666 12.704C6.87832 12.704 6.48166 12.3073 6.48166 11.829C6.48166 11.3506 6.86666 10.954 7.34499 10.954H7.35666C7.83499 10.954 8.23166 11.3506 8.23166 11.829C8.23166 12.3073 7.83499 12.704 7.35666 12.704ZM12.0233 12.704C11.545 12.704 11.1483 12.3073 11.1483 11.829C11.1483 11.3506 11.5333 10.954 12.0117 10.954H12.0233C12.5017 10.954 12.8983 11.3506 12.8983 11.829C12.8983 12.3073 12.5017 12.704 12.0233 12.704ZM16.69 12.704C16.2117 12.704 15.815 12.3073 15.815 11.829C15.815 11.3506 16.2 10.954 16.69 10.954C17.18 10.954 17.565 11.3506 17.565 11.829C17.565 12.3073 17.18 12.704 16.69 12.704Z"
          fill="#777777"
        />
      </svg>
    );
  }
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.28 2.24998C9.64001 2.16998 7.11001 3.15998 5.21001 5.00998C3.31001 6.85998 2.26001 9.33998 2.26001 12V20C2.26001 20.96 3.05001 21.75 4.01001 21.75H12C14.66 21.75 17.14 20.7 18.99 18.8C20.84 16.9 21.82 14.38 21.75 11.73C21.61 6.64998 17.36 2.39998 12.28 2.25998V2.24998ZM17.92 17.75C16.35 19.36 14.25 20.25 12.01 20.25H4.00001C3.86001 20.25 3.75001 20.14 3.75001 20V12C3.75001 9.74998 4.64001 7.64998 6.25001 6.08998C7.80001 4.57998 9.84001 3.74998 12 3.74998C12.08 3.74998 12.16 3.74998 12.24 3.74998C16.54 3.86998 20.13 7.45998 20.25 11.76C20.31 14.01 19.48 16.14 17.92 17.75V17.75Z"
        fill="#777777"
      />
      <path
        d="M12.02 11.25H12.01C11.6 11.25 11.27 11.59 11.27 12C11.27 12.41 11.61 12.75 12.02 12.75C12.43 12.75 12.77 12.41 12.77 12C12.77 11.59 12.43 11.25 12.02 11.25Z"
        fill="#777777"
      />
      <path
        d="M8.02002 11.25H8.01002C7.60002 11.25 7.27002 11.59 7.27002 12C7.27002 12.41 7.61002 12.75 8.02002 12.75C8.43002 12.75 8.77002 12.41 8.77002 12C8.77002 11.59 8.43002 11.25 8.02002 11.25Z"
        fill="#777777"
      />
      <path
        d="M16.02 11.25C15.61 11.25 15.27 11.59 15.27 12C15.27 12.41 15.61 12.75 16.02 12.75C16.43 12.75 16.77 12.41 16.77 12C16.77 11.59 16.44 11.25 16.02 11.25Z"
        fill="#777777"
      />
    </svg>
  );
}
