export default function EyeIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.59 3.65073C11.05 0.830733 7.40496 0.750732 6.99996 0.750732C5.77094 0.761498 4.55704 1.02291 3.43252 1.51897C2.30801 2.01503 1.29653 2.7353 0.459963 3.63573C0.174049 3.9307 0.0100524 4.32274 0.000781753 4.73343C-0.00848887 5.14412 0.137651 5.54317 0.409963 5.85073C2.94996 8.67073 6.59496 8.75073 6.99996 8.75073C8.22898 8.73997 9.44289 8.47856 10.5674 7.9825C11.6919 7.48644 12.7034 6.76616 13.54 5.86573C13.8259 5.57077 13.9899 5.17872 13.9991 4.76803C14.0084 4.35734 13.8623 3.9583 13.59 3.65073ZM12.815 5.17573C12.0717 5.97782 11.1727 6.61987 10.1729 7.06262C9.17304 7.50538 8.09339 7.73952 6.99996 7.75073C6.85496 7.75073 3.43996 7.72073 1.15496 5.18073C1.05032 5.06112 0.995173 4.90613 1.00075 4.74729C1.00632 4.58846 1.07219 4.43772 1.18496 4.32573C1.92818 3.52364 2.8272 2.88159 3.82704 2.43884C4.82688 1.99609 5.90653 1.76195 6.99996 1.75073C7.14496 1.75073 10.56 1.78073 12.845 4.32073C12.9496 4.44035 13.0048 4.59534 12.9992 4.75417C12.9936 4.913 12.9277 5.06374 12.815 5.17573Z"
        fill="#777777"
      />
      <path
        d="M7 2.25073C6.50555 2.25073 6.0222 2.39736 5.61108 2.67206C5.19995 2.94676 4.87952 3.33721 4.6903 3.79402C4.50108 4.25084 4.45157 4.75351 4.54804 5.23846C4.6445 5.72341 4.8826 6.16887 5.23223 6.5185C5.58187 6.86813 6.02732 7.10623 6.51228 7.2027C6.99723 7.29916 7.4999 7.24965 7.95671 7.06043C8.41353 6.87121 8.80397 6.55078 9.07868 6.13966C9.35338 5.72854 9.5 5.24519 9.5 4.75073C9.5 4.08769 9.23661 3.45181 8.76777 2.98297C8.29893 2.51412 7.66304 2.25073 7 2.25073ZM7 6.25073C6.70333 6.25073 6.41332 6.16276 6.16665 5.99794C5.91997 5.83312 5.72771 5.59885 5.61418 5.32476C5.50065 5.05067 5.47095 4.74907 5.52882 4.4581C5.5867 4.16713 5.72956 3.89985 5.93934 3.69007C6.14912 3.48029 6.41639 3.33743 6.70737 3.27955C6.99834 3.22168 7.29994 3.25138 7.57403 3.36491C7.84812 3.47844 8.08238 3.6707 8.24721 3.91738C8.41203 4.16405 8.5 4.45406 8.5 4.75073C8.5 5.14856 8.34197 5.53009 8.06066 5.81139C7.77936 6.0927 7.39783 6.25073 7 6.25073Z"
        fill="#777777"
      />
    </svg>
  );
}
