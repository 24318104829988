import { css } from "@emotion/react";

export default css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    gap: 10px;
  }
`;
