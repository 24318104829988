import Select from "../Select";
import style from "./header.select.css";

export default function HeaderSelect({
	categoryOptions,
	sortOptions,
	onChangeCategory,
	onChangeSort,
	sortValue,
	categoryValue,
	filterMultiple,
}) {
	return (
		<div css={style} className="header-select">
			<Select
				placeholder="카테고리 선택"
				data={categoryOptions}
				onChange={onChangeCategory}
				value={categoryValue}
				isMultiple={filterMultiple}
			/>
			<Select
				placeholder="최신순"
				data={sortOptions}
				onChange={onChangeSort}
				value={sortValue}
			/>
		</div>
	);
}
